import * as Yup from "yup";
import { formatPrice, isPrice } from "../../../ui/helpers";

export const DrumContactDetailsSchema = Yup.object({
  first_name: Yup.string()
    .required("First Name is a required field")
    .matches(/(^[A-Za-z ]+$)/, "Only alphabet characters are allowed"),
  last_name: Yup.string()
    .required("Last Name is a required field")
    .matches(/(^[A-Za-z]+$)/, "Only alphabet characters are allowed"),
  phone: Yup.string()
    .required("Phone Number is a required field")
    .matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
});

export const ChemaddressDetailsSchema = Yup.object({
  chem_contact_address_1: Yup.string().required(
    "Address 1 is a required field"
  ),
  chem_contact_suburb: Yup.string().required("Suburb is a required field"),
  chem_contact_state: Yup.string().required("State is a required field"),
  chem_contact_postcode: Yup.string()
    .required("Postcode is a required field")
    .length(4, "Must be exactly 4 characters")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  chem_address_1: Yup.string().required("Address 1 is a required field"),
  chem_suburb: Yup.string().required("Suburb is a required field"),
  chem_state: Yup.string().required("State is a required field"),
  chem_postcode: Yup.string()
    .required("Postcode is a required field")
    .length(4, "Must be exactly 4 characters")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
});

export const renderProductsRow = (headings, item) => {
  return headings.map(([key]) => (
    <td key={key} className={`align-middle no-line border-0 px-5 py-5`}>
      {isPrice(key) ? (
        <div className="text-right">{formatPrice(item[key])}</div>
      ) : (
        item[key]
      )}
    </td>
  ));
};
