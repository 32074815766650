export const stateOptions = [
  { value: "ACT", label: "ACT" },
  { value: "NSW", label: "NSW" },
  { value: "NT", label: "NT" },
  { value: "QLD", label: "QLD" },
  { value: "SA", label: "SA" },
  { value: "TAS", label: "TAS" },
  { value: "WA", label: "WA" },
  { value: "VIC", label: "VIC" },
];

export const HEADINGS = [
  ["id", "ID"],
  ["product", "Product"],
  ["container_size", "Container Size"],
  ["container_qty", "Container Qty"],
  ["total_qty", "Total Quantity"],
  ["price", "Price Per Litre"],
  ["total_levy", "Total Levy Due"],
];
