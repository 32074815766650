/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import Skeleton from "@material-ui/lab/Skeleton";
import { OrganisationDetails } from "./OrganisationDetails";
import { getOrganisation } from "./_redux/selectors";
import { Contacts } from "../../submodules/Contacts";
import { Sites } from "../../submodules/Sites";
import { OrganisationUsers } from "../../submodules/OrganisationUsers";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getOrganisationById,
  getOrganisationContactsWIthTags,
  getOrganisationTags,
  getSimpleOrganisations
} from "./_api";
import { History } from "../../submodules/History";
import { Files } from "../../submodules/Files";
import { modifyOrganisationData } from "./helpers";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../constants";
import { Prices } from "../../submodules/Prices";
import { QuickBooks } from "./QuickBooks";
import { getUsers } from "../Users/_api";
import { get } from "lodash";

export const Card = ({
  match: {
    params: { id }
  }
}) => {
  const { request } = useFetch();

  const data = useSelector(getOrganisation(id));

  const [cardData, setCardData] = useState({});
  const [organisations, setOrganisations] = useState([]);
  const [organisationsLoading, setOrganisationsLoading] = useState(false);
  const [contactsWithTags, setContactsWithTags] = useState([]);
  const [contactsWithTagsLoading, setContactsWithTagsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const user = useSelector(({ auth: { user } }) => user) || {};
  const isCaAccess = get(user, "permissions.is_ca_access");
  const isRcAccess = get(user, "permissions.is_rc_access");

  const { business_name, quickbooks_vendor_id } = cardData || "";

  useEffect(() => {
    if (!data) {
      Promise.all([
        request(getOrganisationById, id),
        request(getOrganisationTags, id)
      ]).then(([organisation, tags]) => {
        if (!organisation) return;
        setCardData({
          ...modifyOrganisationData(organisation),
          tags
        });
      });
    } else {
      request(getOrganisationTags, id).then(
        tags =>
          tags &&
          setCardData({
            ...data,
            tags
          })
      );
    }
  }, []);

  useEffect(() => {
    setOrganisationsLoading(true);
    request(getSimpleOrganisations)
      .then(data => data && setOrganisations(data))
      .finally(() => setOrganisationsLoading(false));
  }, []);

  useEffect(() => {
    setContactsWithTagsLoading(true);
    request(getOrganisationContactsWIthTags, id)
      .then(data => data && setContactsWithTags(data))
      .finally(() => setContactsWithTagsLoading(false));
  }, []);

  useEffect(() => {
    request(getUsers).then(data => data && setUsers(data));
  }, []);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const tabs = [
    {
      label: "Profile",
      tabPanel: (
        <OrganisationDetails
          name={business_name}
          data={cardData}
          setData={setCardData}
          id={id}
          simpleOrganisations={organisations}
        />
      )
    },
    {
      label: "Contacts",
      tabPanel: (
        <Contacts
          name={business_name}
          organisationId={id}
          simpleOrganisations={organisations}
          contactsWithTags={contactsWithTags}
          contactsWithTagsLoading={contactsWithTagsLoading}
        />
      )
    },
    {
      label: "Sites",
      tabPanel: (
        <Sites
          organisationId={id}
          organisationName={business_name}
          organisations={organisations}
          organisationsLoading={organisationsLoading}
        />
      )
    },
    {
      label: "Files",
      tabPanel: (
        <Files
          organisationName={business_name}
          organisationId={id}
          users={users}
        />
      )
    },
    {
      label: "Prices",
      tabPanel: <Prices name={business_name} organisationId={id} />
    },
    {
      label: "History",
      tabPanel: <History organisationId={id} organisationName={business_name} />
    },
    {
      label: "Linked Users",
      tabPanel: <OrganisationUsers organisationId={id} />
    },
    {
      label: "Quickbooks",
      tabPanel: (
        <QuickBooks organisationId={id} vendorId={quickbooks_vendor_id} />
      )
    }
  ];

  return (
    <div className="mt-10">
      <AccessControl
        desiredRoles={[
          AVAILABLE_ROLES.NATIONAL_ADMIN,
          AVAILABLE_ROLES.AGSAFE_ADMIN
        ]}
      >
        <Tabs
          defaultActive="0"
          tabs={tabs}
          align="right"
          text="rgba(241, 251, 243, 0.7)"
          selectedTabColor="white"
          selectedColor="#407A28"
        />
      </AccessControl>
      <AccessControl
        desiredRoles={[AVAILABLE_ROLES.AGSAFE_STAFF, AVAILABLE_ROLES.RC_ACCESS]}
      >
        <Tabs
          defaultActive="0"
          tabs={tabs.slice(0, -1).filter(({ label }) => isCaAccess || isRcAccess ? !["Prices"].includes(label) : true)}
          align="right"
          text="rgba(241, 251, 243, 0.7)"
          selectedTabColor="white"
          selectedColor="#407A28"
        />
      </AccessControl>
      <AccessControl
        desiredRoles={[
          AVAILABLE_ROLES.END_USER,
          AVAILABLE_ROLES.PROCESSOR,
          AVAILABLE_ROLES.CA_ACCESS,
        ]}
      >
        <Tabs
          defaultActive="0"
          tabs={tabs
            .slice(0, -2)
            .filter(({ label }) => !["History", "Files", "Prices"].includes(label))}
          align="right"
          text="rgba(241, 251, 243, 0.7)"
          selectedTabColor="white"
          selectedColor="#407A28"
        />
      </AccessControl>
    </div>
  );
};
