const config = {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "ap-southeast-2",
  
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "ap-southeast-2_DzjOOsPZA",
  
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: "3jmffnqt3usqdsc1s07k43rmk",
  
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: "app.agsafe.org.au",
        // OPTIONAL - Cookie path
        //path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 1,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
        // CSRF
        sameSite: "lax",
      },
    },
    ApiUrl: {
      Rest: "https://api.app.agsafe.org.au",
      WebSocket: "https://api.app.agsafe.org.au",
    },
    Branding: {
      LoginBackground: {
        "app.agsafe.org.au": "/media/bg/agsafe-bg.jpg",
      },
    },
    CognitoInformation: {
      IssuerName: 'drumDRIVE'
    }
  };
  
  export default config;
  