import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getOrganisationHistoryCount,
  getOrganisationReimbursCount,
  getOrganisationTasksCount,
  getSimpleOrganisations
} from "../../modules/Organisations/_api";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { Tasks } from "./Tasks";
import { OrgReimbursements } from "./OrgReimbursements";
import { OrgSystem } from "./OrgSystem";
import { getContactsSimple } from "../../modules/Contacts/_api";
import { getUsers } from "../../modules/Users/_api";
import { getSitesSimple } from "../../modules/Sites/_api";
import {
  modifyContacts,
  modifyOrganisations,
  modifySites,
  modifyUsers
} from "../../modules/Tasks/helpers";
import { Skeleton } from "@material-ui/lab";

const countStyle = {
  backgroundColor: "#dfe3e8",
  borderRadius: "8px",
  padding: "1px 12px",
  marginLeft: "6px",
  color: "black"
};

export const History = ({ organisationId }) => {
  const { request } = useFetch();
  const [systemList, setSystemList] = useState([]);
  const [reimbursements, setReimbursements] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [tasksCount, setTasksCount] = useState(0);
  const [siteTaskCount, setSiteTasksCount] = useState(0);
  const [contactTasksCount, setContactTasksCount] = useState(0);

  const [historyCount, setHistoryCount] = useState(0);
  const [reimbursementsCount, setReimbursementsCount] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [organisations, setOrganisations] = useState([]);

  const fetchContacts = () => {
    request(getContactsSimple, "simple=true&fields=id,first_name,last_name")
      .then(data => data && modifyContacts(data))
      .then(data => {
        if (!data) return;
        setContacts(data);
      });
  };

  const fetchUsers = () => {
    setUsersLoading(true);
    request(getUsers)
      .then(data => data && modifyUsers(data))
      .then(data => {
        if (!data) return;
        setUsers(data);
      })
      .finally(() => setUsersLoading(false));
  };

  const fetchSites = () => {
    request(getSitesSimple, "simple=true&fields=id,name")
      .then(data => data && modifySites(data))
      .then(data => {
        if (!data) return;
        setSites(data);
      });
  };

  const fetchOrganisations = () => {
    request(getSimpleOrganisations)
      .then(data => data && modifyOrganisations(data))
      .then(data => {
        if (!data) return;
        setOrganisations(data);
      });
  };

  useEffect(() => {
    if (organisationId) {
      Promise.all([
        request(getOrganisationTasksCount, organisationId),
        request(getOrganisationReimbursCount, organisationId),
        request(getOrganisationHistoryCount, organisationId),
        request(getOrganisationTasksCount, organisationId, "type=contact"),
        request(getOrganisationTasksCount, organisationId, "type=site")
      ]).then(
        ([
          taskCount,
          reimbursementsCount,
          historyCount,
          contactTaskCount,
          siteTaskCount
        ]) => {
          taskCount && setTasksCount(taskCount);
          reimbursementsCount && setReimbursementsCount(reimbursementsCount);
          historyCount && setHistoryCount(historyCount);
          contactTaskCount && setContactTasksCount(contactTaskCount);
          siteTaskCount && setSiteTasksCount(siteTaskCount);
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchContacts();
    fetchUsers();
    fetchSites();
    fetchOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    {
      label: (
        <span>
          Tasks (Org) <span style={countStyle}>{tasksCount}</span>
        </span>
      ),
      tabPanel: (
        <Tasks
          data={tasks}
          organisationId={organisationId}
          setTasks={setTasks}
          contacts={contacts}
          users={users}
          sites={sites}
          organisations={organisations}
        />
      )
    },
    {
      label: (
        <span>
          Tasks (Contact) <span style={countStyle}>{contactTasksCount}</span>
        </span>
      ),
      tabPanel: (
        <Tasks
          data={tasks}
          organisationId={organisationId}
          setTasks={setTasks}
          contacts={contacts}
          users={users}
          sites={sites}
          organisations={organisations}
          isContactTasks
        />
      )
    },
    {
      label: (
        <span>
          Tasks (Site) <span style={countStyle}>{siteTaskCount}</span>
        </span>
      ),
      tabPanel: (
        <Tasks
          data={tasks}
          organisationId={organisationId}
          setTasks={setTasks}
          contacts={contacts}
          users={users}
          sites={sites}
          organisations={organisations}
          isSiteTasks
        />
      )
    },
    {
      label: (
        <span>
          System <span style={countStyle}>{historyCount}</span>
        </span>
      ),
      tabPanel: (
        <OrgSystem
          data={systemList}
          setData={setSystemList}
          organisationId={organisationId}
        />
      )
    },
    {
      label: (
        <span>
          Reimbursements <span style={countStyle}>{reimbursementsCount}</span>
        </span>
      ),
      tabPanel: (
        <OrgReimbursements
          organisationId={organisationId}
          data={reimbursements}
          setReimbursements={setReimbursements}
          users={users}
        />
      )
    }
  ];

  if (usersLoading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="bg-white rounded py-7 px-10">
      <Tabs
        defaultActive="0"
        tabs={tabs}
        align="left"
        background="white"
        text="rgb(138, 144, 153)"
        selectedTabColor="white"
        selectedColor="#304FFD"
        boxShadow={false}
      />
    </div>
  );
};
