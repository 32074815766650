import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { organisationsReducer } from "../app/modules/Organisations/_redux/reducer";
import { usersReducer } from "../app/modules/Users/_redux/reducer";
import { tagsReducer } from "../app/modules/Settings/Tags/_redux/reducer";
import { contactsReducer } from "../app/modules/Contacts/_redux/reducer";
import { productsReducer } from "../app/modules/Products/_redux/reducer";
import { sitesReducer } from "../app/modules/Sites/_redux/reducer";
import { reimbursementsReducer } from "../app/modules/ReimbursementsApprovals/_redux/reducer";
import { badgesReducer } from "../app/modules/Settings/Badges/_redux/reducer";
import { consultantsReducer } from "../app/modules/Settings/Consultants/_redux/reducer";
import { regionsReducer } from "../app/modules/Settings/Regions/_redux/reducer";
import { chemclearBookingReducer } from "../app/modules/ChemClearBooking/_redux/reducer";
import { drummusterBookingReducer } from "../app/modules/DrumMusterBooking/_redux/reducer";
import { ManufacturerReducer } from "../app/modules/Manufacturer/_redux/reducer";
import { reimbursementsRecReducer } from "../app/modules/ReimbursementsReconciliation/_redux/reducer";
import { packSizesReducer } from "../app/modules/Settings/PackSizes/_redux/reducer";
import { pricesReducer } from "../app/modules/Settings/Prices/_redux/reducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  organisations: organisationsReducer,
  users: usersReducer,
  tags: tagsReducer,
  contacts: contactsReducer,
  products: productsReducer,
  sites: sitesReducer,
  reimbursements: reimbursementsReducer,
  reimbursementsRec: reimbursementsRecReducer,
  badges: badgesReducer,
  consultants: consultantsReducer,
  regions: regionsReducer,
  chemclearBooking: chemclearBookingReducer,
  drummusterBooking: drummusterBookingReducer,
  manufacturer: ManufacturerReducer,
  packSizes: packSizesReducer,
  prices: pricesReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
