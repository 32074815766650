export const TASK_HEADINGS = [
  ["task_name", "Task Name"],
  ["detail", "Task Details"],
  ["creator_name", "Created By"],
  ["completed_date", "Completed Date"],
  ["created_at", "Start Date"],
  ["num_of_comments", "Comments Count"],
  ["status", "Status"],
];

export const TASKS_SEARCHMAP = {
  "Task Name": "task_name",
  "Task Status": "status",
};

export const TASKS_SEARCH_HELPER = [
  ["task_name", "Task Name"],
  ["status", "Status"],
];

export const REIMBURSEMENT_HEADINGS = [
  ["code", "ID"],
  ["state", "State"],
  ["region", "Region"],
  ["date_requested", "Date Requested"],
  ["volume", "Volume"],
  ["total_value_gst", "Value"],
  ["type", "Type"],
  ["reviewer", "Reviewed By"],
  ["approver", "Approved By"],
  ["closing_date", "Closing Date"],
  ["invoice_number", "Invoice"],
  ["grouped", "Grouped"],
  ["status", "Status"],
];

export const REIMBURSEMENTS_SEARCH_HELPER = [
  ["code", "ID"],
  ["organisation.physical_state", "State"],
  ["organisation.business_name", "Claim Payee Organisation"],
  ["invoice.code", "Invoice"],
  ["grouped", "Grouped"],
];

export const reimbursementsSearchMap = {
  ID: "code",
  State: "organisation.physical_state",
  Region: "organisation.region.long_name",
  "Claim Payee Organisation": "organisation.business_name",
  Invoice: "invoice.code",
  Grouped: "grouped",
};

export const HISTORY_HEADINGS = [
  ["history_id", "ID"],
  ["ABN", "ABN"],
  ["business_name", "Business Name"],
  ["legal_name", "Legal Name"],
  ["email", "Email"],
  ["phone", "Phone"],
  ["physical_address", "Physical Address"],
  ["physical_postcode", "Physical Postcode"],
  ["history_action", "Action"],
  ["history_created_at", "Date"],
];

export const historySearchMap = {
  ID: "history_id",
  Action: "history_action",
};
