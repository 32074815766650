/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ListButton } from "../../../ui/components/ListButton";
import FileUploadIcon from "../../../ui/static/FileUpload.png";
import PDFIcon from "../../../ui/static/PDFIcon.png";
import { renderReimbursementsRow } from "./helpers";
import {
  createReimbursementFile,
  getReimbursementInvoicURL
} from "../../submodules/Files/_api";
import { FileUploadForm } from "../../../ui/structures/FileUploadForm";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  editReimbursementDrops,
  getReimbursementDrops,
  getReviewReimbursementById,
  modifyInvoice,
  modifyInvoiceFile,
  modifyReimbursement
} from "../DrumMuster/Inspections/_api";
import { error, info } from "../../../helpers/toasts";
import { get } from "lodash";
import {
  modifyCCDsArray,
  renderExtendedRow
} from "../DrumMuster/Inspections/helpers";
import { Loader } from "../../../ui/components/Loader";
import { EditReimbursement } from "../ReimbursementsApprovals/EditReimbursement";
import { Modal } from "../../../ui/components/Modal";
import { downloadFile } from "../AWS-S3/download";
import {
  modifyReimbursument,
  rendeTotalDropsRow
} from "../ReimbursementsApprovals/helpers";
import { PreviewPDF } from "../../../ui/structures/PreviewPDF";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { LINE_HEADINGS } from "./constants";
import { getReimbursements } from "./_api";
import { getInvoicePayload } from "../DrumMuster/Claims/helpers";

export const Line = ({
  match: {
    params: { id }
  }
}) => {
  const { request } = useFetch();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [unreimbursedData, setUnreimbursedData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [donwloadURL, setDownloadURL] = useState("");
  const [updateFileForm, setUpdateFileForm] = useState(false);
  const [item, setItem] = useState({});
  const [groupedItems, setGroupedItems] = useState([]);
  const [groupedLoading, setGroupedLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request(getReviewReimbursementById, id)
      .then(reimbursement => {
        if (!reimbursement) return;
        setItem(modifyReimbursument(reimbursement));
        request(getReimbursementDrops, reimbursement.id).then(
          drops => drops && setData(modifyCCDsArray(drops))
        );
        if (reimbursement.status === "admin_review") {
          const fileID = get(reimbursement, "invoice.files[0].id");
          if (!fileID) {
            error("Invoice not found!");
            return;
          }
          request(
            getReimbursementInvoicURL,
            reimbursement.id,
            get(reimbursement, "invoice.id"),
            [fileID]
          ).then(data => data && setDownloadURL(data.download_url));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!item?.grouped) return;
    setGroupedLoading(true);
    request(getReimbursements, `invoice_id=${item.invoice_id}`)
      .then(data => data && setGroupedItems(data))
      .finally(() => setGroupedLoading(false));
  }, [item]);

  const handleModalClose = () => {
    setUpdateFileForm(false);
    setModalOpen(false);
  };
  const handleModalOpen = () => setModalOpen(true);

  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);

  const handleSave = () => {
    setActionLoading(true);
    request(
      editReimbursementDrops,
      id,
      data.map(({ id }) => ({ id }))
    )
      .then(() => {
        if (!data) return;
        info("Reimbursement has been updated!");
        history.push("/reconciliation-reimbursements");
      })
      .finally(() => setActionLoading(false));
  };

  const handleUpdateFile = (
    values,
    setSubmitting,
    uploadedFile,
    fileData,
    setLoading,
    handleClose,
    type
  ) => {
    if (!uploadedFile) return;
    // eslint-disable-next-line eqeqeq
    if (values.invoice_total == 0) {
      setSubmitting(false);
      setLoading(false);
      error("Invoice total can't be 0!");
      return;
    }
    setLoading(true);
    request(modifyInvoiceFile, {}, id, get(item, "invoice.id"), fileData.id);
    request(
      modifyInvoice,
      getInvoicePayload(type, values),
      id,
      get(item, "invoice.id")
    )
      .then(data => {
        if (!data) return;
        info("File has been uploaded!");
        handleModalClose();
        history.push("/reconciliation-reimbursements");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleDelete = itemID => {
    setData(state => state.filter(({ id }) => id !== itemID));
    setUnreimbursedData(state => [
      ...state,
      data.find(({ id }) => id === itemID)
    ]);
  };

  const handleDownload = () => {
    const fileID = get(item, "invoice.files[0].id");
    if (!fileID) {
      error("Invoice not found!");
      return;
    }
    setActionLoading(true);
    downloadFile(
      id,
      get(item, "invoice.id"),
      setActionLoading,
      getReimbursementInvoicURL,
      fileID
    );
  };

  const handleApprove = () => {
    if (item.status === "waiting for invoice") {
      error(
        "Please attach an invoice before attempting to approve this reimbursement"
      );
      return;
    }
    setActionLoading(true);
    return request(modifyReimbursement, { status: "active" }, id)
      .then(data => {
        if (!data) return;
        info("Record has been approved!");
        history.push("/reconciliation-reimbursements");
      })
      .finally(() => setActionLoading(false));
  };

  const renderHeader = () => {
    const invoiceCode = get(item, "invoice.code", "");
    const organisationName = get(item, "organisation.business_name", "");
    if (invoiceCode && organisationName) {
      return invoiceCode + ", " + organisationName;
    } else if (invoiceCode && !organisationName) return invoiceCode;
    else if (!invoiceCode && organisationName) return organisationName;
    else return "";
  };

  const renderPDFPreview = () => {
    if (item.status !== "admin review") return null;
    const fileName = get(item, "invoice.files[0].file_name", "");
    const fileFormat = fileName.split(".")[1];
    if (fileFormat !== "pdf") return;
    return donwloadURL ? (
      <div className="mt-10 mr-5 approval-content-preview">
        <PreviewPDF donwloadURL={donwloadURL} />
      </div>
    ) : (
      <div className="mt-10 mr-5 approval-content-preview">
        <Skeleton variant="rect" width={700} height={990} />
      </div>
    );
  };

  const handleUploadNewFile = () => {
    setUpdateFileForm(true);
    handleModalOpen();
  };

  const getItemType = (groupedItems = []) => {
    const isProcessingType = groupedItems.find(
      ({ type }) => type === "processing"
    );
    const isInspectionType = groupedItems.find(
      ({ type }) => type === "inspection"
    );

    if (isInspectionType && isProcessingType) {
      return "Combined";
    } else if (isProcessingType) return "Processing";
    else if (isInspectionType) return "Inspection";
  };

  const getGroupedData = () => {
    const inspectionItems = groupedItems.filter(
      ({ type }) => type === "inspection"
    );
    const processingItems = groupedItems.filter(
      ({ type }) => type === "processing"
    );
    return {
      ...item,
      total_value: groupedItems.reduce(
        (acc, value) => acc + value.total_value,
        0
      ),
      inspection_fees: inspectionItems.reduce(
        (acc, value) => acc + value.total_value,
        0
      ),
      processing_fees: processingItems.reduce(
        (acc, value) => acc + value.total_value,
        0
      ),
      type: getItemType(groupedItems)
    };
  };

  return (
    <>
      <Modal
        isOpen={editOpen}
        submitable
        onClose={handleEditClose}
        maxWidth="lg"
        modalContent={
          <EditReimbursement
            data={unreimbursedData}
            setData={setUnreimbursedData}
            setReimbursedData={setData}
            handleClose={handleEditClose}
            organisationId={item.organisation_id}
          />
        }
      />
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <FileUploadForm
        handleClose={handleModalClose}
        isOpen={modalOpen}
        id={id}
        invoiceID={get(item, "invoice.id")}
        fileID={get(item, "invoice.files[0].id")}
        apiCall={createReimbursementFile}
        name="invoice"
        handleSubmit={handleUpdateFile}
        gstStatus={get(item, "organisation.gst_status")}
        updateFileForm={updateFileForm}
        isGroupedItem={item?.grouped}
        data={item?.grouped ? getGroupedData() : item}
      />
      <div className="w-100 approval-content-wrapper">
        {renderPDFPreview()}
        <div className="row justify-content-center mt-10 approval-content-data">
          <div className="col-12">
            <div className="bg-white rounded py-7 px-10">
              <h3 className="text-dark mb-5">{renderHeader()}</h3>
              <div className="mb-10 mr-5 d-flex justify-content-between">
                <ListButton
                  label="Back"
                  size="small"
                  onClick={() => history.push("/reconciliation-reimbursements")}
                  variant="outlined"
                  text="#407A28"
                  boxShadow={false}
                />
                <ListButton label="Add" onClick={handleEditOpen} />
              </div>
              <Datagrid
                data={data}
                headings={LINE_HEADINGS}
                renderRow={renderReimbursementsRow}
                loading={loading}
                extendable
                renderExtendedRow={renderExtendedRow}
                defaultExtendable
                paginationDefaultValue={20}
                deletable
                handleDelete={handleDelete}
                footerRow={() => rendeTotalDropsRow(data, item)}
              />
            </div>
          </div>
        </div>
      </div>
      {item.grouped ? (
        <div className="my-3 text-warning text-right font-weight-bold">
          *Approving this item will result into approving{" "}
          {groupedItems.length - 1} more grouped{" "}
          {groupedItems.length - 1 > 1 ? "items" : "item"}
        </div>
      ) : null}
      <div className="mt-15 d-flex justify-content-between">
        <div className="w-25">
          {item.status === "admin review" ? (
            <>
              <div className="d-flex align-items-center mb-9">
                <div onClick={handleDownload} className="cursor-pointer">
                  <img src={PDFIcon} alt="pdf" />
                </div>
                <div className="ml-5">
                  <strong>{get(item, "invoice.files[0].file_name")}</strong>
                </div>
              </div>
              <div>
                <ListButton
                  label="Upload New File"
                  onClick={handleUploadNewFile}
                  disabled={groupedLoading}
                />
              </div>
            </>
          ) : (
            <div>
              <img src={FileUploadIcon} alt="logo" width="25%" />
              <p className="mb-3">
                <strong>Upload invoice</strong>
              </p>
              <div>
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  className="text-white px-7"
                  size="large"
                  outline="none"
                  onClick={handleModalOpen}
                  disabled={groupedLoading}
                >
                  Upload Invoice
                </Button>
              </div>
            </div>
          )}
        </div>

        <div>
          <ListButton
            label="save"
            className="px-18 py-3 mr-3"
            onClick={handleSave}
            disabled={loading}
          />
          <ListButton
            label="approve"
            className="px-14 py-3"
            onClick={handleApprove}
            disabled={groupedLoading || loading}
          />
        </div>
      </div>
    </>
  );
};
