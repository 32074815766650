/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useCallback } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { checkIsActive } from "../../../../_helpers";
import {
  ITEM_TYPES,
  ADMIN_MENU,
  STAFF_MENU,
  RC_MENU,
  CA_MENU,
  PROCESSOR_MENU,
  END_USER_MENU,
} from "./constants";
import "./AsideColorFix.css";

export function AsideMenuList({ layoutProps }) {
  const user = useSelector(({ auth: { user } }) => user) || {};

  const isNationalAdmin = get(user, "permissions.is_global_admin");
  const isAgsafeAdmin = get(user, "permissions.is_agsafe_admin");
  const isAgsafeStaff = get(user, "permissions.is_agsafe_staff");
  const isRcAccess = get(user, "permissions.is_rc_access");
  const isProcessor = get(user, "permissions.is_processor");
  const isCaAccess = get(user, "permissions.is_ca_access");
  const isEndUser = get(user, "permissions.is_end_user");
  const isInvoiceApprover = get(user, "permissions.is_approver");

  const renderMenu = () => {
    if (isNationalAdmin) return ADMIN_MENU;
    if (isAgsafeAdmin) return ADMIN_MENU;
    if (isAgsafeStaff) return STAFF_MENU;
    if (isRcAccess) return RC_MENU;
    if (isProcessor) return PROCESSOR_MENU;
    if (isCaAccess) return CA_MENU;
    if (isEndUser) return END_USER_MENU;
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {(renderMenu() || []).map((item, index) => {
          if (item.title === "Approvals" && !isInvoiceApprover) return null;
          return <MenuItem key={index} item={item} />;
        })}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

function MenuItem({ item, subMenu }) {
  const location = useLocation();
  const getMenuItemActive = useCallback(
    (url) => {
      return checkIsActive(location, url)
        ? "menu-item-active menu-item-open"
        : "";
    },
    [location]
  );

  const getMenuItemTextActive = useCallback(
    (url) => {
      return checkIsActive(location, url) ? "text-active " : "";
    },
    [location]
  );

  const getMenuBackgroundActive = useCallback(
    (url) => {
      return checkIsActive(location, url) ? "bg-active" : "";
    },
    [location]
  );

  const disabledItems = ["/chem-clear"];

  const { type } = item;
  switch (type) {
    case ITEM_TYPES.link:
      return (
        <>
          {disabledItems.includes(item.to) ? (
            <li className={`menu-item ${getMenuItemActive(item.to)}`}>
              <span
                className={`disabled menu-link ${getMenuBackgroundActive(
                  item.to
                )}`}
                to={item.to}
              >
                {Boolean(subMenu) && (
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                )}
                {Boolean(item.icon) && (
                  <span className="svg-icon menu-icon">{item.icon}</span>
                )}
                <span
                  className={`disabled-text menu-text ${getMenuItemTextActive(
                    item.to
                  )}`}
                >
                  {item.title}
                </span>
              </span>
            </li>
          ) : (
            <li className={`menu-item ${getMenuItemActive(item.to)}`}>
              <NavLink
                className={`menu-link ${getMenuBackgroundActive(item.to)}`}
                to={item.to}
              >
                {Boolean(subMenu) && (
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                )}
                {Boolean(item.icon) && (
                  <span className="svg-icon menu-icon">{item.icon}</span>
                )}
                <span className={`menu-text ${getMenuItemTextActive(item.to)}`}>
                  {item.title}
                </span>
              </NavLink>
            </li>
          )}
        </>
      );
    case ITEM_TYPES.heading:
      return (
        <li className="menu-item" style={{ pointerEvents: "none" }}>
          <div className="menu-link">
            <span
              className="menu-text font-weight-bold"
              style={{ color: "#464E5F", cursor: "default" }}
            >
              {item.title}
            </span>
          </div>
        </li>
      );
    case ITEM_TYPES.menu:
      return (
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            item.to
          )}`}
          data-menu-toggle="hover"
        >
          <NavLink
            className={`menu-link menu-toggle ${getMenuBackgroundActive(
              item.to
            )}`}
            to={item.to}
          >
            <span className="svg-icon menu-icon">{item.icon}</span>
            <span className={`menu-text ${getMenuItemTextActive(item.to)}`}>
              {item.title}
            </span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent">
                <span className="menu-link">
                  <span
                    className={`menu-text ${getMenuItemTextActive(item.to)}`}
                  >
                    {item.title}
                  </span>
                </span>
              </li>
              {item.items.map((subItem, index) => (
                <MenuItem key={index} item={subItem} subMenu />
              ))}
            </ul>
          </div>
        </li>
      );
    default:
      return null;
  }
}
