import React from "react";
export const ListHeader = ({ title, renderButtons }) => {
  return (
    <div className="d-flex mb-5 justify-content-between align-items-center">
      <h3 className="text-dark">{title}</h3>
      <div className="mb-5 d-flex justify-content-between align-items-center">
        {renderButtons && renderButtons()}
      </div>
    </div>
  );
};
