/* eslint-disable no-unused-vars */
import React from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListButton } from "../../../ui/components/ListButton";
import { DrumContactDetailsSchema } from "./helpers";

export const ContactDetails = ({ handleNextStep, contactDetails = {} }) => {
  const { request } = useFetch();

  const { first_name, last_name, email, phone, manufacturer, levy_period } = contactDetails;

  const initialValues = {
    first_name,
    last_name,
    email,
    phone,
    manufacturer,
    levy_period,
  };

  const inputStype = {
    minHeight: "65px",
    width: "49%",
  };

  const handleSubmit = (values) => {
    handleNextStep(values, "contact_details");
  };

  return (
    <div className="border border-secondary bg-white p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={DrumContactDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <h3 className="mb-10">
              <strong>Your Contact Details</strong>
            </h3>
            <div className="d-flex my-5 justify-content-between flex-wrap">
              <div className="mb-5" style={inputStype}>
                <MappedInput label="First Name" name="first_name" data-testid="first_name" />
              </div>
              <div className="mb-5" style={inputStype}>
                <MappedInput label="Last Name" name="last_name" data-testid="last_name" />
              </div>
              <div className="mb-5" style={inputStype}>
                <MappedInput label="Phone" name="phone" data-testid="phone" />
              </div>
              <div className="mb-5" style={inputStype}>
                <MappedInput label="Email" name="email" data-testid="email" />
              </div>
              <div className="mb-5" style={inputStype}>
                <MappedInput label="Manufacturer" name="manufacturer" data-testid="manufacturer" />
              </div>
              <div className="mb-5" style={inputStype}>
                <MappedInput label="Levy Period" name="levy_period" data-testid="levy_period" />
              </div>
            </div>
            <div className="mt-10 text-right">
              <ListButton
                label="Next"
                size="medium"
                className="px-12"
                boxShadow={false}
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
