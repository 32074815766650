/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { HEADINGS } from "./constants";
import { renderProductsRow } from "./helpers";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { ListButton } from "../../../ui/components/ListButton";
import { useFetch } from "../../../hooks/fetch.hook";

const mockedProducts = [
  {
    id: "AN0001",
    product: "Steel",
    container_size: "Less than 20",
    container_qty: "2",
    total_qty: "1",
    price: 0.06,
    total_levy: "",
  },
  {
    id: "AN0002",
    product: "Plastic",
    container_size: "200-250L",
    container_qty: "1L",
    total_qty: "1",
    price: 0.05,
    total_levy: "",
  },
  {
    id: "AN0003",
    product: "Steel",
    container_size: "75L",
    container_qty: "2",
    total_qty: "2",
    price: 0.06,
    total_levy: "",
  },
];

export const Products = ({ handlePrevStep, handleSubmit }) => {
  const { request } = useFetch();

  const handleExpand = (id) => () => console.log(id);

  const [data, setData] = useState(mockedProducts);
  const [submitting, setSubmitting] = useState(false);

  const handleSearch = (search, isRemoveKey) => {
    setData(
      (isRemoveKey ? mockedProducts : data).filter((item) => {
        const _search = Object.entries(search).map((item) => [
          HEADINGS.find((i) => i[1] === item[0])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    );
  };

  const resetSearch = () => {
    setData(mockedProducts);
  };

  const onSubmit = () => {
    handleSubmit("values", setSubmitting);
  };

  const KEY_MAP = {
    ID: data.map((item) => item.id),
    Product: data.map((item) => item.product),
    "Container Size": data.map((item) => item.container_size),
    "Container Qty": data.map((item) => item.container_qty),
  };

  return (
    <>
      <hr />
      <div className="bg-white pb-15 pt-5 px-10">
        <div className="col-12 justify-content-center ">
          <h3 className="text-dark mb-7">Add Container / Product</h3>
          <div className="mb-15">
            <SearchBar
              onSearch={handleSearch}
              clearSearch={resetSearch}
              keyMap={KEY_MAP}
              placeholder="Search..."
              elevation={2}
              chipBackgroundColor="#E8F5E1"
              chipColor="#255915"
            />
          </div>
          <ListHeader title="My Products" />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderProductsRow}
            expandable
            handleExpand={handleExpand}
            expandHeader=""
          />
        </div>
        <div className="mt-10 d-flex justify-content-end">
          <ListButton
            label="Back"
            size="medium"
            className="px-12 mr-3"
            boxShadow={false}
            onClick={handlePrevStep}
            variant="outlined"
            text="#64A70B"
          />
          <ListButton
            label="Submit"
            size="medium"
            className="px-12"
            boxShadow={false}
            onClick={onSubmit}
            disabled={submitting}
          />
        </div>
      </div>
    </>
  );
};
