import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { Autocomplete } from "../../../../ui/components/Autocomplete";

export const MailingAddress = ({ storageValues, handleStorageListen}) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    Object.keys(storageValues).forEach((fieldName) => {
      setFieldValue(fieldName, storageValues[fieldName]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageValues]);

  return (
    <div style={{ width: "48%" }}>
      <h3 className="mb-10">
        <strong>Mailing Address</strong>
      </h3>
      <div className="mb-7">
        <Autocomplete
          options={[]}
          name="chem_storage_address"
          placeholder="Search Mailing Address"
          disabled={values.as_contact_address}
          hanldeFocus={handleStorageListen}
        />
      </div>
      <MappedInput
        label="Address 1"
        name="chem_address_1"
        data-testid="chem_address_1"
        disabled={values.as_contact_address}
      />
      <MappedInput
        label="Address 2"
        name="chem_address_2"
        data-testid="chem_address_2"
        disabled={values.as_contact_address}
      />
      <MappedInput
        label="Town / Suburb"
        name="chem_suburb"
        data-testid="chem_suburb"
        disabled={values.as_contact_address}
      />
      <MappedInput
        label="State"
        name="chem_state"
        data-testid="chem_state"
        disabled={values.as_contact_address}
      />
      <MappedInput
        label="Postcode"
        name="chem_postcode"
        data-testid="chem_postcode"
        disabled={values.as_contact_address}
      />
    </div>
  );
};
