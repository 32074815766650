import config from "react-global-configuration";
import { modifyAbnSearchValue } from "../helpers";

export const addOrganisation = data =>
  fetch(`${config.get("ApiUrl").Rest}/organisations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getOrganisations = (
  count_only = "",
  search = "",
  archived = "",
  field,
  direction,
  page = 0,
  page_size = 10
) => {
  return fetch(
    `${config.get("ApiUrl").Rest}/organisations?${count_only &&
      "count_only=y"}${search &&
      "&" + modifyAbnSearchValue(search)}${(archived && "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};

export const getProcessors = () => {
  return fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations?tags.name=Processor&simple=true&fields=business_name,id`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};

export const getOrganisationsSimple = (query = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/organisations${query ? `?${query}` : ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};

export const getOrganisationHistory = (id = "", search = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/organisations/${id}/history?${search &&
      "&" + search}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};

export const getOrganisationsListAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  field,
  direction,
  search,
  archived
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations?&page_size=${page_size}${(archived && "&status=all") ||
      ""}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        modifyAbnSearchValue(autocompleteText) +
        ")_") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}${search ? "&" + modifyAbnSearchValue(search) : ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getOrganisationsAmount = (search = "", archived = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/organisations?count_only=y${
      search ? "&" + modifyAbnSearchValue(search) : ""
    }${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};

export const getDashboardClients = () => {
  return fetch(`${config.get("ApiUrl").Rest}/organisations?count_only=y`, {
    mode: "cors",
    credentials: "include"
  });
};

export const getTopCustomers = () =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/top5`, {
    mode: "cors",
    credentials: "include"
  });

export const getSimpleOrganisations = () =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations?simple=True&fields=id,ABN,legal_name,business_name`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getOrganisationById = organisation_id =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}`, {
    mode: "cors",
    credentials: "include"
  });

export const modifyOrganisation = (data, organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const archiveOrganisation = id =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });

export const getOrganisationContacts = (
  organisation_id,
  search = "",
  archived = "",
  field,
  direction,
  page = 0,
  page_size = 10
) => {
  return fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/contacts?${search &&
      "&" + search}${(archived && "&status=all") || ""}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};

export const getOrgContactsSimple = (organisation_id, query = "") =>
  fetch(
    `${config.get("ApiUrl").Rest}/organisations/${organisation_id}/contacts${
      query ? `?${query}` : ""
    }`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getOrganisationContactsWIthTags = organisation_id => {
  return fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/contacts?simple=true&fields=id,first_name,last_name,tags`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};

export const getOrganisationContactsAmount = (
  organisation_id,
  search = "",
  archived = ""
) => {
  return fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/contacts?count_only=y${
      search ? "&" + search : ""
    }${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};

export const getOrganisationTags = organisation_id =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/tags`, {
    mode: "cors",
    credentials: "include"
  });

export const editOrganisationTags = (organisation_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/tags`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const deleteOrganisationTags = (organisation_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/tags`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getOrganisationSites = (
  organisation_id,
  archived = "",
  field,
  direction
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/sites?${(archived && "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getOrganisationUsers = organisation_id =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/users`, {
    mode: "cors",
    credentials: "include"
  });

export const getOrganisationTests = organisation_id =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/test_requests`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const linkUsers = (data, organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/users`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const unlinkUsers = (data, organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/users`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const abnLookup = abn =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/abn-lookup?search_only=true&abn=${abn}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getOrganisationTasks = (
  organisation_id,
  search = "",
  field,
  direction,
  page = 0,
  page_size = 10,
  type = "organisation"
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/tasks?${search &&
      "&" + search}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}&type=${type}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getOrganisationTasksCount = (organisation_id, search = "") =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/tasks?count_only=y${
      search ? "&" + search : ""
    }`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getOrganisationReimbursCount = organisation_id =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/reimbursements?count_only=y&status=approved&organisation_id=${organisation_id}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getOrganisationHistoryCount = organisation_id =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/history?count_only=y`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getOrganisationTasksAutocomplete = (
  organisation_id,
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/tasks?&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getQuickbooksVendors = () => {
  return fetch(
    `${config.get("ApiUrl").Rest}/organisations/quickbooks_vendors`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};
