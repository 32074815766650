import { Skeleton } from "@material-ui/lab";
import React, { useState, useEffect, useMemo } from "react";
import { info } from "../../../helpers/toasts";
import { useFetch } from "../../../hooks/fetch.hook";
import { Autocomplete } from "../../../ui/components/Autocomplete";
import { ListButton } from "../../../ui/components/ListButton";
import { Loader } from "../../../ui/components/Loader";
import { getQuickbooksVendors, modifyOrganisation } from "./_api";

export const QuickBooks = ({ organisationId, vendorId = "" }) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  const setVendor = (name, value) => {
    setSelectedId(value);
  };

  const handleSubmit = () => {
    const payload = {
      quickbooks_vendor_id: selectedId,
    };
    setLoading(true);
    request(modifyOrganisation, payload, organisationId)
      .then((data) => data && info("Organisation has been matched!"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    request(getQuickbooksVendors)
      .then((data) => data && setVendors(data))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vendorOptions = useMemo(
    () =>
      vendors.map((venodr) => ({
        value: venodr.Id,
        label: venodr.CompanyName,
      })),
    [vendors]
  );

  if (!vendors.length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="border border-secondary bg-white p-10">
      <Loader
        isOpen={loading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <h3 className="mb-10">
        <strong>Match Quickbooks Vendor</strong>
      </h3>
      <div className="w-50">
        <div>
          <Autocomplete
            name=""
            placeholder="Quickbooks Vendors"
            setValue={setVendor}
            defaultValueField="value"
            options={vendorOptions}
            className="mb-3"
            defaultValue={String(vendorId)}
          />
        </div>
        <div className="text-right mt-10">
          <ListButton
            label="Save"
            size="large"
            boxShadow={false}
            onClick={handleSubmit}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};
