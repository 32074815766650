/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Stepper, Step, StepLabel, makeStyles } from "@material-ui/core";
import { ContactDetails } from "./ContactDetails";
import { AddressDetails } from "./AddressDetails/index";
import { Products } from "./Products";
import { setData } from "./_redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px",
    justifyContent: "center",
  },
}));

export const Manufacturer = () => {
  const [step, setStep] = useState(0);

  const dispatch = useDispatch();

  const {
    data: { contact_details, address_details },
  } = useSelector((state) => state.manufacturer);

  const handleNextStep = (data, key) => {
    dispatch(setData({ data, key }));
    setStep(step + 1);
  };

  const handlePrevStep = () => setStep(step - 1);

  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(false);
  };

  const stepsContent = [
    <ContactDetails handleNextStep={handleNextStep} contactDetails={contact_details} />,

    <AddressDetails
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      addressDetails={address_details}
    />,

    <Products handlePrevStep={handlePrevStep} handleSubmit={handleSubmit} />,
  ];

  const steps = [
    {
      label: "Your Details",
      icon: <PersonAddIcon fontSize="large" />,
      key: "contact_details",
    },
    {
      label: "Addresses",
      icon: <PersonAddIcon fontSize="large" />,
      key: "address_details",
    },
    {
      label: "Products",
      icon: <PersonAddIcon fontSize="large" />,
      key: "products",
    },
  ];

  const classes = useStyles();

  return (
    <div className="bg-white">
      <h3 className="pt-10 pl-10">
        <strong>DrumMuster Levy Declaration</strong>
      </h3>
      <Stepper activeStep={step} connector={null} classes={classes}>
        {steps.map(({ label }, index) => (
          <Step key={label}>
            <StepLabel icon={null}>
              <span
                style={{ fontSize: "14px" }}
                className={`text-uppercase ${index === step ? "text-primary" : ""}`}
              >
                <strong> {label}</strong>
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="mt-5">{stepsContent.find((item, index) => index === step)}</div>
    </div>
  );
};
