import React from "react";
import { ReactComponent as DashboardIcon } from "../../../../../ui/static/Dashboard.svg";
import { ReactComponent as OrganisationsIcon } from "../../../../../ui/static/Organisations.svg";
import { ReactComponent as SettingsIcon } from "../../../../../ui/static/Settings.svg";
import { ReactComponent as IntegrationsIcon } from "../../../../../ui/static/Integrations.svg";
import { ReactComponent as UsersIcon } from "../../../../../ui/static/Users.svg";
import { ReactComponent as ClearIcon } from "../../../../../ui/static/Tests.svg";
import { ReactComponent as ProductsIcon } from "../../../../../ui/static/Crops.svg";
import { ReactComponent as ReimbursementsIcon } from "../../../../../ui/static/Invoices.svg";

export const ITEM_TYPES = {
  link: "link",
  heading: "heading",
  menu: "menu"
};

export const ADMIN_MENU = [
  {
    type: ITEM_TYPES.link,
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard"
  },
  {
    type: ITEM_TYPES.link,
    title: "СhemClear",
    icon: <ClearIcon />,
    to: "/chem-clear"
  },
  {
    type: ITEM_TYPES.link,
    title: "Products",
    icon: <ProductsIcon />,
    to: "/products-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Organisations",
    icon: <OrganisationsIcon />,
    to: "/organisations/organisations-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Sites",
    icon: <DashboardIcon />,
    to: "/sites-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Contacts",
    icon: <UsersIcon />,
    to: "/contacts-list"
  },
  {
    type: ITEM_TYPES.menu,
    title: "drumMUSTER",
    icon: <DashboardIcon />,
    to: "/drummuster",
    items: [
      {
        type: "link",
        title: "Inspections",
        to: "/drummuster/inspections"
      },
      {
        type: "link",
        title: "Claims",
        to: "/drummuster/claims"
      },
      {
        type: "link",
        title: "Scheduling",
        to: "/drummuster/scheduling"
      },
      {
        type: "link",
        title: "Processing",
        to: "/drummuster/processing"
      }
    ]
  },
  {
    type: ITEM_TYPES.link,
    title: "Review",
    icon: <ReimbursementsIcon />,
    to: "/reconciliation-reimbursements"
  },
  {
    type: ITEM_TYPES.link,
    title: "Approvals",
    icon: <DashboardIcon />,
    to: "/approve-reimbursements"
  },
  {
    type: ITEM_TYPES.link,
    title: "Backtrack",
    icon: <ProductsIcon />,
    to: "/backtrack"
  },
  {
    type: ITEM_TYPES.menu,
    title: "Settings",
    icon: <SettingsIcon />,
    to: "/settings",
    items: [
      {
        type: "link",
        title: "Tags",
        to: "/settings/tags"
      },
      {
        type: "link",
        title: "Badges",
        to: "/settings/badges"
      },
      {
        type: "link",
        title: "Regions",
        to: "/settings/regions"
      },
      {
        type: "link",
        title: "Consultants",
        to: "/settings/consultants"
      },
      {
        type: "link",
        title: "Pack Sizes",
        to: "/settings/pack-sizes"
      },
      {
        type: "link",
        title: "Prices",
        to: "/settings/prices"
      },
      {
        type: "link",
        title: "Help",
        to: "/settings/help"
      }
    ]
  },
  {
    type: ITEM_TYPES.link,
    title: "Integrations",
    icon: <IntegrationsIcon />,
    to: "/integrations"
  },
  {
    type: ITEM_TYPES.link,
    title: "Users",
    icon: <UsersIcon />,
    to: "/users-list"
  }
];

export const STAFF_MENU = [
  {
    type: ITEM_TYPES.link,
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard"
  },
  {
    type: ITEM_TYPES.link,
    title: "СhemClear",
    icon: <ClearIcon />,
    to: "/chem-clear"
  },
  {
    type: ITEM_TYPES.link,
    title: "Products",
    icon: <ProductsIcon />,
    to: "/products-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Organisations",
    icon: <OrganisationsIcon />,
    to: "/organisations/organisations-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Sites",
    icon: <DashboardIcon />,
    to: "/sites-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Contacts",
    icon: <UsersIcon />,
    to: "/contacts-list"
  },
  {
    type: ITEM_TYPES.menu,
    title: "drumMUSTER",
    icon: <DashboardIcon />,
    to: "/drummuster",
    items: [
      {
        type: "link",
        title: "Inspections",
        to: "/drummuster/inspections"
      },
      {
        type: "link",
        title: "Claims",
        to: "/drummuster/claims"
      },
      {
        type: "link",
        title: "Scheduling",
        to: "/drummuster/scheduling"
      },
      {
        type: "link",
        title: "Processing",
        to: "/drummuster/processing"
      }
    ]
  },
  {
    type: ITEM_TYPES.link,
    title: "Review",
    icon: <ReimbursementsIcon />,
    to: "/reconciliation-reimbursements"
  },
  {
    type: ITEM_TYPES.link,
    title: "Approvals",
    icon: <DashboardIcon />,
    to: "/approve-reimbursements"
  },
  {
    type: ITEM_TYPES.link,
    title: "Users",
    icon: <UsersIcon />,
    to: "/users-list"
  }
];

export const RC_MENU = [
  {
    type: ITEM_TYPES.link,
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard"
  },
  {
    type: ITEM_TYPES.link,
    title: "СhemClear",
    icon: <ClearIcon />,
    to: "/chem-clear"
  },
  {
    type: ITEM_TYPES.link,
    title: "Products",
    icon: <ProductsIcon />,
    to: "/products-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Organisations",
    icon: <OrganisationsIcon />,
    to: "/organisations/organisations-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Sites",
    icon: <DashboardIcon />,
    to: "/sites-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Contacts",
    icon: <UsersIcon />,
    to: "/contacts-list"
  },
  {
    type: ITEM_TYPES.menu,
    title: "drumMUSTER",
    icon: <DashboardIcon />,
    to: "/drummuster",
    items: [
      {
        type: "link",
        title: "Inspections",
        to: "/drummuster/inspections"
      },
      {
        type: "link",
        title: "Claims",
        to: "/drummuster/claims"
      },
      {
        type: "link",
        title: "Scheduling",
        to: "/drummuster/scheduling"
      },
      {
        type: "link",
        title: "Processing",
        to: "/drummuster/processing"
      }
    ]
  }
];

export const PROCESSOR_MENU = [
  {
    type: ITEM_TYPES.link,
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard"
  },
  {
    type: ITEM_TYPES.link,
    title: "Organisations",
    icon: <OrganisationsIcon />,
    to: "/organisations/organisations-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Sites",
    icon: <DashboardIcon />,
    to: "/sites-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Contacts",
    icon: <UsersIcon />,
    to: "/contacts-list"
  },
  {
    type: ITEM_TYPES.menu,
    title: "drumMUSTER",
    icon: <DashboardIcon />,
    to: "/drummuster",
    items: [
      {
        type: "link",
        title: "Inspections",
        to: "/drummuster/inspections"
      },
      {
        type: "link",
        title: "Claims",
        to: "/drummuster/claims"
      },
      {
        type: "link",
        title: "Processing",
        to: "/drummuster/processing"
      }
    ]
  }
];

export const CA_MENU = [
  {
    type: ITEM_TYPES.link,
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard"
  },
  {
    type: ITEM_TYPES.link,
    title: "Organisations",
    icon: <OrganisationsIcon />,
    to: "/organisations/organisations-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Sites",
    icon: <DashboardIcon />,
    to: "/sites-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Contacts",
    icon: <UsersIcon />,
    to: "/contacts-list"
  },
  {
    type: ITEM_TYPES.menu,
    title: "drumMUSTER",
    icon: <DashboardIcon />,
    to: "/drummuster",
    items: [
      {
        type: "link",
        title: "Inspections",
        to: "/drummuster/inspections"
      },
      {
        type: "link",
        title: "Claims",
        to: "/drummuster/claims"
      }
    ]
  }
];

export const END_USER_MENU = [
  {
    type: ITEM_TYPES.link,
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard"
  },
  {
    type: ITEM_TYPES.link,
    title: "Organisations",
    icon: <OrganisationsIcon />,
    to: "/organisations/organisations-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Sites",
    icon: <DashboardIcon />,
    to: "/sites-list"
  },
  {
    type: ITEM_TYPES.link,
    title: "Contacts",
    icon: <UsersIcon />,
    to: "/contacts-list"
  },
  {
    type: ITEM_TYPES.menu,
    title: "drumMUSTER",
    icon: <DashboardIcon />,
    to: "/drummuster",
    items: [
      {
        type: "link",
        title: "Inspections",
        to: "/drummuster/inspections"
      },
      {
        type: "link",
        title: "Claims",
        to: "/drummuster/claims"
      }
    ]
  }
];
