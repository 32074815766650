import * as Yup from "yup";

export const OrganisationDetailsSchema = () =>
  Yup.object({
    business_name: Yup.string().required(
      "Organisation Name is a required field"
    ),
    legal_name: Yup.string().required("Legal Name is a required field"),
    phone: Yup.string()
      .required("Phone Number is a required field")
      .matches(/(^[0-9+ ]+$)/, "Only numbers are allowed")
      .test(
        "length",
        "Must be at least 6 digits",
        val => (val || "").replace(/[\s+]/g, "").length >= 6
      )
      .test(
        "length",
        "Must be at most 12 digits",
        val => (val || "").replace(/[\s+]/g, "").length <= 12
      ),
    mobile: Yup.string()
      .matches(/(^[0-9+ ]+$)/, "Only numbers are allowed")
      .test("length", "Must be at least 6 digits", val =>
        (val || "").length
          ? (val || "").replace(/[\s+]/g, "").length >= 6
          : true
      )
      .test("length", "Must be at most 12 digits", val =>
        (val || "").length
          ? (val || "").replace(/[\s+]/g, "").length <= 12
          : true
      ),
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email Address is a required field"),
    physical_address: Yup.string().required(
      "Physical Address is a required field"
    ),
    physical_address2: Yup.string(),
    physical_state: Yup.string().required("Physical State is a required field"),
    physical_city: Yup.string().required("Physical City is a required field"),
    physical_postcode: Yup.string()
      .required("Postcode is a required field")
      .length(4, "Postcode must be exactly 4 characters")
      .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
    postal_address: Yup.string().required("Postal Address is a required field"),
    postal_address2: Yup.string(),
    postal_state: Yup.string().required("Postal State is a required field"),
    postal_postcode: Yup.string()
      .required("Postcode is a required field")
      .length(4, "Postcode must be exactly 4 characters")
      .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
    ABN: Yup.string()
      .required()
      .matches(/(^[0-9 ]+$)/, "Must be 11 digits"),
    status: Yup.string().required("Status is required"),
    lga_region: Yup.string()
      .required("LGA Region is a required field")
      .nullable()
  });

export const ContactDetailsSchema = Yup.object().shape(
  {
    phone: Yup.string()
      .matches(/(^[0-9+ ]+$)/, "Only numbers are allowed")
      .test("length", "Must be at least 6 digits", val =>
        (val || "").length
          ? (val || "").replace(/[\s+]/g, "").length >= 6
          : true
      )
      .test("length", "Must be at most 12 digits", val =>
        (val || "").length
          ? (val || "").replace(/[\s+]/g, "").length <= 12
          : true
      )
      .nullable()
      .when("mobile", {
        is: mobile => !mobile || mobile.length === 0,
        then: Yup.string().required("Phone or Mobile is required"),
        otherwise: Yup.string()
      }),
    mobile: Yup.string()
      .matches(/(^[0-9+ ]+$)/, "Only numbers are allowed")
      .test("length", "Must be at least 6 digits", val =>
        (val || "").length
          ? (val || "").replace(/[\s+]/g, "").length >= 6
          : true
      )
      .test("length", "Must be at most 12 digits", val =>
        (val || "").length
          ? (val || "").replace(/[\s+]/g, "").length <= 12
          : true
      )
      .nullable()
      .when("phone", {
        is: phone => !phone || phone.length === 0,
        then: Yup.string().required("Phone or Mobile is required"),
        otherwise: Yup.string()
      }),
    first_name: Yup.string()
      .required("First Name is a required field")
      .matches(
        /(^[a-zA-Z\u00C0-\u024F '-]+$)/,
        "Only alphabet characters are allowed"
      ),
    preferred_name: Yup.string()
      .matches(
        /(^[a-zA-Z\u00C0-\u024F '-]+$)/,
        "Only alphabet characters are allowed"
      )
      .nullable(),
    last_name: Yup.string()
      .required("Last Name is a required field")
      .matches(
        /(^[a-zA-Z\u00C0-\u024F '-]+$)/,
        "Only alphabet characters are allowed"
      ),
    email: Yup.string()
      .required("Email is a required field")
      .email("Must be a valid email")
  },
  [["phone", "mobile"]]
);

export const ConsultantDetailSchema = Yup.object({
  first_name: Yup.string()
    .required("First Name is a required field")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F '-]+$)/,
      "Only alphabet characters are allowed"
    ),
  last_name: Yup.string()
    .required("Last Name is a required field")
    .matches(
      /(^[a-zA-Z\u00C0-\u024F '-]+$)/,
      "Only alphabet characters are allowed"
    ),
  email: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email"),
  phone: Yup.string()
    .required("Phone Number is a required field")
    .matches(/(^[0-9+ ]+$)/, "Only numbers are allowed")
    .test(
      "length",
      "Must be at least 6 digits",
      val => (val || "").replace(/[\s+]/g, "").length >= 6
    )
    .test(
      "length",
      "Must be at most 12 digits",
      val => (val || "").replace(/[\s+]/g, "").length <= 12
    ),
  physical_address: Yup.string().required(
    "Physical Address is a required field"
  ),
  physical_address2: Yup.string(),
  physical_state: Yup.string().required("Physical State is a required field"),
  physical_postcode: Yup.string()
    .required("Postcode is a required field")
    .length(4, "Postcode must be exactly 4 characters")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  user_id: Yup.string().required("User is a required field"),
  lga_region: Yup.string()
    .required("LGA Region is a required field")
    .nullable()
});

export const SiteCardSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .matches(/(^[0-9+ ]+$)/, "Only numbers are allowed")
    .test("length", "Must be at least 6 digits", val =>
      (val || "").length ? (val || "").replace(/[\s+]/g, "").length >= 6 : true
    )
    .test(
      "length",
      "Must be at most 12 digits",
      val => (val || "").replace(/[\s+]/g, "").length <= 12
    ),
  email: Yup.string().email("Must be a valid email"),
  status: Yup.string().required("Status is required"),
  physical_postcode: Yup.string()
    .required("Postcode is required")
    .length(4, "Postcode must be exactly 4 characters")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  induction_text: Yup.string().when("induction_type", {
    is: type => type === "online" || type === "manual",
    then: Yup.string().required("Required field"),
    otherwise: Yup.string().strip()
  }),
  dm_width: Yup.number().typeError("Must be a number"),
  dm_length: Yup.number().typeError("Must be a number"),
  dm_height: Yup.number().typeError("Must be a number"),
  lga_region: Yup.string()
    .required("LGA Region is a required field")
    .nullable()
});

export const userProfileSchema = findDuplicate =>
  Yup.object({
    first_name: Yup.string()
      .required("First Name is required")
      .matches(
        /(^[a-zA-Z\u00C0-\u024F '-]+$)/,
        "Only alphabet characters are allowed"
      ),
    last_name: Yup.string()
      .required("Last Name is required")
      .matches(
        /(^[a-zA-Z\u00C0-\u024F '-]+$)/,
        "Only alphabet characters are allowed"
      ),
    phone_number: Yup.string().when("preferred_mfa", {
      is: type => type === "SMS_MFA",
      then: schema =>
        schema
          .matches(/(^[0-9+ ]+$)/, "Only numbers are allowed")
          .required("Required field")
          .test(
            "length",
            "Must be at least 6 digits",
            val => (val || "").replace(/[\s+]/g, "").length >= 6
          )
          .test(
            "length",
            "Must be at most 12 digits",
            val => (val || "").replace(/[\s+]/g, "").length <= 12
          )
    }),
    email: Yup.string()
      .required("Email is required")
      .email("Must be a valid email")
      .test(
        "EMail duplicate",
        "User already exists",
        value => value !== findDuplicate(value)
      ),
    preferred_mfa: Yup.string().required(
      "Multi-factor authentication (MFA) is a required field"
    )
  });

export const UserPasswordSchema = Yup.object({
  old_password: Yup.string().required("Current Password is required"),
  new_password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])[A-Za-z\d!@#$%^&*()\-_=+{};:,<.>]{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirm_password: Yup.string()
    .required("Please confirm your password")
    .when("new_password", {
      is: new_password =>
        new_password && new_password.length > 0 ? true : false,
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Password doesn't match"
      )
    })
});

export const confirmPasswordSchema = Yup.object({
  code: Yup.string().required("Code is required"),
  new_password: Yup.string().required("New Password is required")
});

export const customerLookupSchema = Yup.object({
  biller_org_id: Yup.string().required("Please select a customer")
});

export const testOrgLookupSchema = Yup.object({
  tester_org_id: Yup.string().required("Please select a testing organisation")
});

export const siteLookupSchema = Yup.object({
  test_site_id: Yup.string().required("Please select a site")
});

export const productLookupSchema = Yup.object({
  crop_id: Yup.string().required("Please select a product")
});

export const requestCallSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  phone_number: Yup.string()
    .required("Phone Number is required")
    .matches(/(^[0-9+, ]+$)/, "Only numbers are allowed")
    .test(
      "length",
      "Must be at least 6 digits",
      val => (val || "").replace(/[\s+]/g, "").length >= 6
    )
    .test(
      "length",
      "Must be at most 12 digits",
      val => (val || "").replace(/[\s+]/g, "").length <= 12
    ),
  message: Yup.string().required("Message is required")
});

export const CropDetailsSchema = Yup.object({
  name: Yup.string().required("Common Name is a required field")
});

export const TagsDetailsSchema = Yup.object({
  name: Yup.string().required("Name is a required field"),
  category: Yup.string().required("Category is a required field")
});

export const PacksDetailsSchema = Yup.object({
  name: Yup.string().required("Name is a required field")
});

export const RegionDetailsSchema = Yup.object({
  long_name: Yup.string().required("Long Name is a required field"),
  short_name: Yup.string().required("Short Name is a required field")
});

export const BadgesDetailsSchema = Yup.object({
  name: Yup.string().required("Name is a required field")
});

export const TrainingDetailsSchema = Yup.object({
  badge_name: Yup.string().required("Name is a required field")
});

export const PriceListSchema = Yup.object({
  price_list_id: Yup.string().required("Price List is required"),
  sales_price: Yup.number().required("Sell Price is required"),
  test_id: Yup.string().required("Test is required"),
  start_date: Yup.string().required("Start Date is required")
});

export const AnalyteDetailsSchema = Yup.object({
  name: Yup.string().required("Analyte Name is a required field"),
  reporting_unit: Yup.string().required(
    "Limit of Reporting Value is a required field"
  ),
  reporting_value: Yup.string().required("Reporting Unit is a required field")
});

export const testRequestStatusSchema = Yup.object({
  status: Yup.string().required("Status is a required field"),
  date: Yup.string().required("Date is a required field")
});

export const groupDetailsSchema = Yup.object({
  name: Yup.string().required("Name is a required field"),
  type: Yup.string().required("Type is a required field"),
  description: Yup.string().required("Description is a required field")
});

export const policyDetailsSchema = Yup.object({
  action: Yup.string().required("Action is a required field"),
  effect: Yup.string().required("Effect is a required field"),
  policy_pattern: Yup.string().required("Policy Pattern is a required field")
});

export const clientDetailsSchema = Yup.object({
  client_id: Yup.string().required("Client ID is a required field"),
  client_secret: Yup.string().required("Client Secret is a required field")
});

export const crsKeyFormSchema = Yup.object({
  api_key: Yup.string().required("API Key is a required field")
});

export const priceListSchema = Yup.object({
  name: Yup.string().required("Price List Name is a required field")
});

export const fileFormSchema = Yup.object({
  category: Yup.string().required("Category is a required field"),
  description: Yup.string().required("Description is a required field"),
  expiry_date: Yup.string().when("category", {
    is: category => category === "insurance",
    then: Yup.string()
      .required("Expiry date is a required field")
      .nullable(),
    otherwise: Yup.string()
      .strip()
      .nullable()
  })
});

export const invoiceUpladSchema = Yup.object({
  code: Yup.string().required("Invoice Number is a required field"),
  invoice_total: Yup.string().required("Invoice Total is a required field"),
  date: Yup.string().required("Invoice date is a required field")
});

export const productDetailsSchema = Yup.object({
  name: Yup.string().required("Name is a required field"),
  manufacturer: Yup.string().required("Manufacturer is a required field"),
  product_type: Yup.string().required("Type is a required field"),
  product_group: Yup.string().required("Group is a required field")
});

export const schedulingSchema = Yup.object({
  volume: Yup.string().required("Volume is a required field"),
  processor_id: Yup.string().required("Required field"),
  planned_pickup_date: Yup.string().required("Required field")
});

export const CompleteClearanceSchema = Yup.object({
  site_name: Yup.string().required("Site Name is a required field"),
  material: Yup.string().required("Required field"),
  pickup_date: Yup.string().required("Required field"),
  20: Yup.string().test(
    "oneOfRequired",
    "At least one of Processed sizes is required",
    function(item) {
      return (
        this.parent["20"] ||
        this.parent["20-25"] ||
        this.parent["26-120"] ||
        this.parent["121-205"]
      );
    }
  ),
  "20-25": Yup.string().test(
    "oneOfRequired",
    "At least one of Processed sizes is required",
    function(item) {
      return (
        this.parent["20"] ||
        this.parent["20-25"] ||
        this.parent["26-120"] ||
        this.parent["121-205"]
      );
    }
  ),
  "26-120": Yup.string().test(
    "oneOfRequired",
    "At least one of Processed sizes is required",
    function(item) {
      return (
        this.parent["20"] ||
        this.parent["20-25"] ||
        this.parent["26-120"] ||
        this.parent["121-205"]
      );
    }
  ),
  "121-205": Yup.string().test(
    "oneOfRequired",
    "At least one of Processed sizes is required",
    function(item) {
      return (
        this.parent["20"] ||
        this.parent["20-25"] ||
        this.parent["26-120"] ||
        this.parent["121-205"]
      );
    }
  )
});

export const taskDetailsSchema = Yup.object({
  task_name: Yup.string().required("Name is a required field"),
  detail: Yup.string().required("Detail is a required field"),
  flag: Yup.string().required("Flag is a required field"),
  task_type: Yup.string().required("Type is required field"),
  user_id: Yup.string().required("Select at least one participant")
});

export const EndUserORgEditSchema = Yup.object({
  detail: Yup.string().required("Details is a required field"),
  first_name: Yup.string().matches(
    /(^[a-zA-Z\u00C0-\u024F '-]+$)/,
    "Only alphabet characters are allowed"
  ),
  last_name: Yup.string().matches(
    /(^[a-zA-Z\u00C0-\u024F '-]+$)/,
    "Only alphabet characters are allowed"
  ),
  email: Yup.string().email("Must be a valid email"),
  mobile: Yup.string()
    .matches(/(^[0-9+ ]+$)/, "Only numbers are allowed")
    .test(
      "length",
      "Must be at least 6 digits",
      val => (val || "").replace(/[\s+]/g, "").length >= 6 || !val
    )
    .test(
      "length",
      "Must be at most 12 digits",
      val => (val || "").replace(/[\s+]/g, "").length <= 12 || !val
    )
});

export const addBusinessSchema = Yup.object({
  ABN: Yup.string().required("ABN is a required field"),
  business_name: Yup.string().required("Organisation Name is a required field"),
  first_name: Yup.string().matches(
    /(^[a-zA-Z\u00C0-\u024F '-]+$)/,
    "Only alphabet characters are allowed"
  ),
  last_name: Yup.string().matches(
    /(^[a-zA-Z\u00C0-\u024F '-]+$)/,
    "Only alphabet characters are allowed"
  ),
  email: Yup.string().email("Must be a valid email"),
  mobile: Yup.string()
    .matches(/(^[0-9+ ]+$)/, "Only numbers are allowed")
    .test(
      "length",
      "Must be at least 6 digits",
      val => (val || "").replace(/[\s+]/g, "").length >= 6 || !val
    )
    .test(
      "length",
      "Must be at most 12 digits",
      val => (val || "").replace(/[\s+]/g, "").length <= 12 || !val
    )
});

export const ActivitySchema = Yup.object({
  end_date: Yup.date().when(
    "start_date",
    (start_date, schema) =>
      start_date && schema.min(start_date, "Can't be earlier than start date")
  )
});

export const PriceDetailsSchema = Yup.object({
  ccd_material: Yup.string().required("Material is a required field"),
  ccd_size: Yup.string().required("Size is a required field"),
  type: Yup.string().required("Type is a required field"),
  price: Yup.number().required("Price is a required field")
});

export const SplitInventorySchema = Yup.object({
  plastic_0: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  plastic_1: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  plastic_2: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  plastic_3: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  steel_0: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  steel_1: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  steel_2: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  steel_3: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  new_plastic_0: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  new_plastic_1: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  new_plastic_2: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  new_plastic_3: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  new_steel_0: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  new_steel_1: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  new_steel_2: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  ),
  new_steel_3: Yup.string().matches(
    /^\d*[0-9]\d*$/,
    "Only positive numbers are allowed"
  )
});

export const InstantProcessSchema = Yup.object({
  organisation_id: Yup.string().required("Organisation is a required field"),
  site_id: Yup.string().required("Site is a required field")
});

export const MoodleTrainingSchema = Yup.object({
  expiry_in_months: Yup.number().typeError("Must be a number")
});
