import { drumActions } from "./actions";

const initialState = {
  data: {
    contact_details: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      manufacturer: "",
      levy_period: ""
    },
    address_details: {
      contact_address_1: "",
      contact_address_2: "",
      contact_suburb: "",
      contact_state: "",
      contact_postcode: "",
      drum_address_1: "",
      drum_address_2: "",
      drum_suburb: "",
      drum_state: "",
      drum_postcode: "",
      as_contact_address: false,
    },
  },
};

export function ManufacturerReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case drumActions.SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.key]: { ...payload.data },
        },
      };

    case drumActions.RESET:
      return initialState;

    default:
      return state;
  }
}
